import { useQuery, useMutation } from '@apollo/client';
import { useUser } from '@luigiclaudio/ga-auth-theme';
import { GET_USER, UPDATE_USER } from './actionTypes';

const useUpdateAppUser = (onCompletedMutation = null, onCompletedQuery = null) => {
    const [user] = useUser();

    const { data: userData, refetch } = useQuery(GET_USER, {
        variables: { netlifyId: user?.id },
        onCompleted: onCompletedQuery,
    });

    const data = userData?.findUserByNetlifyID;

    const [
        updateUser,
        { loading: isLoadingUserPreferences, error: isErrorUserPreferences },
    ] = useMutation(UPDATE_USER, { onCompleted: onCompletedMutation });

    return { updateUser, isLoadingUserPreferences, isErrorUserPreferences, data, refetch };
};

export default useUpdateAppUser;
