import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import { AuthAccount, useUser, AuthLogoutButton } from '@luigiclaudio/ga-auth-theme';
import { H1, H2, H3, P } from '@luigiclaudio/ga-baseline-ui/typography';
import { Loader, useHasMounted, TransitionItem } from '@luigiclaudio/ga-baseline-ui/helpers';
import { Button } from '@luigiclaudio/ga-baseline-ui/button';
import { AlertFeedback } from '@luigiclaudio/ga-baseline-ui/alert';
import handleCustomerPortal from '../../../utils/handleCustomerPortal';
import useAppUser from '../../../services/useAppUser';
import accountUtils from '../../../utils/accountUtils';
import PreferenceContainer from './PreferenceContainer';
import useUpdateAppUser from '../../../services/useUpdateAppUser';
import PreferenceGroups from './PreferenceGroups';
import PreferenceCountries from './PreferenceCountries';
import AccountDetailsCol from './AccountDetailsCol';
import AccountDetailsColDivider from './AccountDetailsColDivider';
import PreferenceHeader from './PreferenceHeader';
import MainContainer from '../../pageLayout/MainContainer';
import PreferencesDivider from './PreferencesDivider';
import appConfig from '../../../data/appConfig.json';
import SiteFooter from '../../pageLayout/SiteFooter';

const Account = ({ intl, location }) => {
    const { multiCountry } = appConfig;
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;
    const [user] = useUser();
    const { href: currentLocation } = location;
    const {
        isLoadingSubscription,
        isErrorSubscription,
        subscriptionType,
        userActiveGroups,
        userActiveCountries,
        username,
    } = useAppUser();

    const [currentUser, setCurrentUser] = useState(user.user_metadata.full_name);

    const [preferenceHasCompleted, setPreferenceHasCompleted] = useState(false);
    const [isLoadingCustomerPortal, setIsLoadingCustomerPortal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        updateUser,
        isLoadingUserPreferences,
        // errorUserPreferences,
        data,
        refetch,
    } = useUpdateAppUser(() => setPreferenceHasCompleted(true));

    const handleGroups = async (group) => {
        if (!data.groups.includes(group)) {
            await updateUser({
                variables: {
                    ...data,
                    groups: [...data.groups, group],
                },
            });
            await refetch();
        }

        if (data.groups.includes(group)) {
            await updateUser({
                variables: {
                    ...data,
                    groups: data.groups.filter((item) => item !== group),
                },
            });
            await refetch();
        }
    };

    const handleCountries = async (country) => {
        if (!data.countries.includes(country)) {
            await updateUser({
                variables: {
                    ...data,
                    countries: [...data.countries, country],
                },
            });
            await refetch();
        }

        if (data.countries.includes(country)) {
            await updateUser({
                variables: {
                    ...data,
                    countries: data.countries.filter((item) => item !== country),
                },
            });
            await refetch();
        }
    };

    useEffect(() => {
        if (preferenceHasCompleted) {
            setTimeout(() => {
                setIsLoading(false);
            }, 350);
        }
    }, [preferenceHasCompleted]);

    if (isLoadingSubscription && hasMounted) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    if (username && isErrorSubscription) {
        return (
            <AlertFeedback
                inProp
                alertType="danger"
                alertMessage={intl.formatMessage({ id: 'user.isErrorSubscription' })}
            />
        );
    }

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <TransitionItem appear inProp>
                    <MainContainer>
                        <SiteContentWrapper>
                            <Container>
                                <Row justifyContent="center">
                                    <Col xxs={30} lg={26}>
                                        <Row justifyContent="center">
                                            <Col xxs={30} sm={12} lg={8}>
                                                <H1>
                                                    <FormattedMessage id="account.preferences.title" />
                                                </H1>
                                            </Col>
                                            <Col xxs={30} sm={18} lg={22}>
                                                <Row>
                                                    <Col xxs={30}>
                                                        <PreferenceHeader>
                                                            <H2>
                                                                <FormattedMessage id="account.preferences.brands.title" />
                                                            </H2>
                                                        </PreferenceHeader>
                                                    </Col>
                                                    <AccountDetailsCol>
                                                        <PreferenceGroups
                                                            userActiveGroups={userActiveGroups}
                                                            onClickGroup={(group) => {
                                                                handleGroups(group);
                                                                setPreferenceHasCompleted(false);
                                                                setIsLoading(true);
                                                            }}
                                                            hasCompleted={
                                                                !isLoadingUserPreferences &&
                                                                preferenceHasCompleted
                                                            }
                                                            isLoadingUserPreferences={isLoading}
                                                        />
                                                    </AccountDetailsCol>
                                                    <AccountDetailsColDivider />
                                                    {multiCountry && (
                                                        <AccountDetailsCol>
                                                            <PreferenceCountries
                                                                userActiveCountries={
                                                                    userActiveCountries
                                                                }
                                                                onClickCountry={(country) => {
                                                                    handleCountries(country);
                                                                    setPreferenceHasCompleted(
                                                                        false,
                                                                    );
                                                                    setIsLoading(true);
                                                                }}
                                                                hasCompleted={
                                                                    !isLoadingUserPreferences &&
                                                                    preferenceHasCompleted
                                                                }
                                                                isLoadingUserPreferences={isLoading}
                                                            />
                                                        </AccountDetailsCol>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xxs={30}>
                                        <PreferencesDivider />
                                    </Col>
                                    <Col xxs={30} lg={26}>
                                        <Row justifyContent="center">
                                            <Col xxs={30} sm={12} lg={8}>
                                                <H1>
                                                    <FormattedMessage id="account.account.title" />
                                                </H1>
                                            </Col>
                                            <Col xxs={30} sm={18} lg={22}>
                                                <Row>
                                                    <Col xxs={30}>
                                                        <PreferenceHeader>
                                                            <H2>
                                                                <FormattedMessage id="account.account.subscription.title" />
                                                            </H2>
                                                        </PreferenceHeader>
                                                    </Col>
                                                    <AccountDetailsCol>
                                                        <PreferenceContainer>
                                                            {subscriptionType && (
                                                                <>
                                                                    <H3>
                                                                        <FormattedMessage
                                                                            id="account.account.subscription.manageSubscription.title"
                                                                            values={{
                                                                                subscription: (
                                                                                    <FormattedMessage
                                                                                        id={accountUtils.subscriptionLabel(
                                                                                            subscriptionType,
                                                                                        )}
                                                                                    />
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </H3>
                                                                    <P>
                                                                        <FormattedMessage
                                                                            id="account.account.subscription.manageSubscription.description"
                                                                            values={{
                                                                                subscription: (
                                                                                    <FormattedMessage
                                                                                        id={accountUtils.subscriptionLabel(
                                                                                            subscriptionType,
                                                                                        )}
                                                                                    />
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </P>
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleCustomerPortal({
                                                                                token: user.token,
                                                                                returnUrl: currentLocation,
                                                                            });
                                                                            setIsLoadingCustomerPortal(
                                                                                true,
                                                                            );
                                                                        }}
                                                                        skin="muted"
                                                                        loading={
                                                                            isLoadingCustomerPortal
                                                                        }
                                                                        disabled={isLoading}
                                                                    >
                                                                        <FormattedMessage id="account.account.subscription.manage.button.label" />
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </PreferenceContainer>
                                                    </AccountDetailsCol>
                                                    <AccountDetailsColDivider />
                                                </Row>
                                                <Row>
                                                    <Col xxs={30}>
                                                        <PreferenceHeader>
                                                            <H2>
                                                                <FormattedMessage id="account.account.account.title" />
                                                            </H2>
                                                        </PreferenceHeader>
                                                    </Col>
                                                    <AccountDetailsCol>
                                                        <PreferenceContainer>
                                                            <AuthAccount
                                                                disabled={isLoading}
                                                                buttonSkin="muted"
                                                                updatedUserCallback={() =>
                                                                    setCurrentUser(
                                                                        user.user_metadata
                                                                            .full_name,
                                                                    )
                                                                }
                                                            />
                                                        </PreferenceContainer>
                                                    </AccountDetailsCol>
                                                    <AccountDetailsColDivider />
                                                    <AccountDetailsCol>
                                                        <PreferenceContainer>
                                                            <H3>
                                                                <FormattedMessage id="account.account.signout.title" />
                                                            </H3>
                                                            <P>
                                                                <FormattedMessage
                                                                    id="account.account.signout.subtitle"
                                                                    values={{
                                                                        username: currentUser,
                                                                    }}
                                                                />
                                                            </P>
                                                            <AuthLogoutButton
                                                                btnType="default"
                                                                skin="muted"
                                                                disabled={isLoading}
                                                            />
                                                        </PreferenceContainer>
                                                    </AccountDetailsCol>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </SiteContentWrapper>
                    </MainContainer>
                </TransitionItem>
            }
            footer={<SiteFooter />}
        />
    );
};

Account.propTypes = {
    intl: PropTypes.shape().isRequired,
    location: PropTypes.shape({
        href: PropTypes.string,
    }).isRequired,
};

export default injectIntl(Account);
